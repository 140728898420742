import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { handleLogout } from "./Logout"
import axios from "axios"

const initialState = {
  loading: false,
  userData: null,
  username: null,
  Data: null,
}

export const gettingUser = createAsyncThunk(
  "user/gettingUser",
  async (argToken, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI
    try {
      const headers = {
        "Content-Type": "application/json; charset=utf8",
        Authorization: `Bearer ${argToken}`, // adding the token
      }
      const res = await fetch(`https://ccl.apps.domefdh.com/api/user/`, {
        headers,
      })

      if (res.status !== 200) {
        throw new Error(" failed")
      }

      const data = await res.json()
      return data
    } catch (error) {
      if(error.response.status == 401 || error.response.status == 400){
        handleLogout(dispatch); // Logout on error
        }
      return rejectWithValue(error)
    }
  }
)

export const getUserData = createAsyncThunk(
  'user/getUserData',
  async ({token}, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await axios.get('https://ccl.apps.domefdh.com/api/user/', {
        headers: { authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      if(error.response.status == 401 || error.response.status == 400){
        handleLogout(dispatch); // Logout on error
      }
      return rejectWithValue(error.response?.data || 'Failed to fetch user data');
    }
  }
);


const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    removeUser: (state, action) => {
      return {
        ...state,
        userData: null,
        username: null,
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(gettingUser.pending, (state, action) => {
        return {
          ...state,
          loading: true,
        }
      })
      .addCase(gettingUser.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          userData: action.payload,
          username: action.payload.username,
        }
      })
      .addCase(gettingUser.rejected, (state, action) => {
        console.log(action.error.message)
        // if(action.payload?.response?.status === 401){
        //     return{
        //       ...state,
        //       userData: null,
        //       username: null,
        //       loading: false,
        //     }
        // }
        return {
          ...state,
          loading: false,
        }
      })

      .addCase(getUserData.pending, (state, action) => {
        return {
          ...state,
          loading: true,
        }
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          Data: action.payload,
        }
      })
      .addCase(getUserData.rejected, (state, action) => {
        console.log(action.error.message)
        return {
          ...state,
          loading: false,
        }
      })
  },
})

export const { removeUser } = UserSlice.actions
export default UserSlice.reducer
