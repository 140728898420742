import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { handleLogout } from "./Logout"

const initialState = {
  expired: false,
}

export const sessionSlice = createSlice({
  name: "sessionSlice",
  initialState,
  reducers: {
    expired_: (state , action) => {
      console.log("expired kkkkk", action.payload)
        state.expired = action.payload
    }
  },
})

export const { expired_ } = sessionSlice.actions
export default sessionSlice.reducer
