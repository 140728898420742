exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-3-js": () => import("./../../../src/pages/about-3.js" /* webpackChunkName: "component---src-pages-about-3-js" */),
  "component---src-pages-build-facade-d-manually-js": () => import("./../../../src/pages/build-facade/d-manually.js" /* webpackChunkName: "component---src-pages-build-facade-d-manually-js" */),
  "component---src-pages-build-facade-draw-js": () => import("./../../../src/pages/build-facade/draw.js" /* webpackChunkName: "component---src-pages-build-facade-draw-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-cladcut-profile-js": () => import("./../../../src/pages/cladcut_profile.js" /* webpackChunkName: "component---src-pages-cladcut-profile-js" */),
  "component---src-pages-cladcuthome-js": () => import("./../../../src/pages/cladcuthome.js" /* webpackChunkName: "component---src-pages-cladcuthome-js" */),
  "component---src-pages-cladcutjobs-js": () => import("./../../../src/pages/cladcutjobs.js" /* webpackChunkName: "component---src-pages-cladcutjobs-js" */),
  "component---src-pages-cladinvoice-js": () => import("./../../../src/pages/cladinvoice.js" /* webpackChunkName: "component---src-pages-cladinvoice-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dxf-js": () => import("./../../../src/pages/dxf.js" /* webpackChunkName: "component---src-pages-dxf-js" */),
  "component---src-pages-dxfchoices-js": () => import("./../../../src/pages/dxfchoices.js" /* webpackChunkName: "component---src-pages-dxfchoices-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-payment-response-js": () => import("./../../../src/pages/payment-response.js" /* webpackChunkName: "component---src-pages-payment-response-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-profile-details-js": () => import("./../../../src/pages/profile-details.js" /* webpackChunkName: "component---src-pages-profile-details-js" */),
  "component---src-pages-project-landing-js": () => import("./../../../src/pages/project-landing.js" /* webpackChunkName: "component---src-pages-project-landing-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-pages-welcome-js": () => import("./../../../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */),
  "component---src-pages-windmaster-profile-js": () => import("./../../../src/pages/windmaster_profile.js" /* webpackChunkName: "component---src-pages-windmaster-profile-js" */),
  "component---src-pages-xlsx-js": () => import("./../../../src/pages/xlsx.js" /* webpackChunkName: "component---src-pages-xlsx-js" */),
  "component---src-templates-accept-facade-js": () => import("./../../../src/templates/accept-facade.js" /* webpackChunkName: "component---src-templates-accept-facade-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

