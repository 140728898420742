import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import configure from "../../UserAccess/configure.json"
import axios from "axios"

// start login
export const loginAction = createAsyncThunk(
  "authSlice/loginAction",
  async (arg, { rejectWithValue }) => {
    const { username, password } = arg
    try {
      const response = await axios.post(
        `${configure.server}/login/`,
        {
          username,
          password,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      console.log(response)

      if (response.data.error) {
        return new Error(response.data.error)
      }
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)
// end login

const initialState = {
  loading: false,
  error: null,
}

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      //start login
      .addCase(loginAction.pending, state => {
        return {
          ...state,
          loading: false,
          error: null,
        }
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          error: null,
        }
      })
      .addCase(loginAction.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.payload.detail,
        }
      })
    // end login

    
  },
})

//   export const { } = loginSlice.actions;

export default authSlice.reducer
