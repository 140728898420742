import { configureStore } from "@reduxjs/toolkit"
import logSlice from "./Slice1"
import windloadSlice from "./SliceWindload"
import userSlice from "./UserSlice"
import UserSlice from "../Auth/Login/UserAuth"
import LoginSlice from "../Auth/Login/LoginSlice"
import emailSlice from "./emailSlice"
import countrySlice from "./countrySlice"
import sessionSlice from "../Auth/Login/expired"
import authSlice from "./supplies/auth"

export const store = configureStore({
  reducer: {
    authSlice,
    sessionSlice,
    logSlice: logSlice,
    windloadSlice: windloadSlice,
    userSlice: userSlice,
    UserSlice: UserSlice,
    loginSlice: LoginSlice,
    emailSlice: emailSlice,
    countrySlice: countrySlice,
  },
})
