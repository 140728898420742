import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../Auth/Login/LoginSlice";
import { removeUser } from "../Redux/UserSlice";
import { expired_ } from "../Auth/Login/expired";

const SessionExpired = () => {
  const dispatch = useDispatch();
  const [countdown, setCountdown] = useState(3); // Countdown state

  const handleLogout = () => {
    localStorage.clear("personalData");
    dispatch(logout());
    dispatch(removeUser());

    console.log("before expired")

    dispatch(expired_(false));
    navigate("/login");
  };
  

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(timer);
          handleLogout();
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, []);

  return (
    <div className="position-absolute w-100 vh-100 top-0 left-0" style={{ zIndex: "1000" }}>
      <div className="d-flex flex-column justify-content-center align-items-center vh-100 bg-light">
        <div className="card shadow p-4 text-center">
          <div className="alert alert-warning d-flex align-items-center" role="alert">
            <i className="bi bi-exclamation-triangle-fill me-2"></i>
            <strong>Warning:</strong> Your session has expired!
          </div>
          <p className="text-muted">Logging out in <strong>{countdown}</strong> seconds...</p>
        </div>
      </div>
    </div>
  );
};

export default SessionExpired;
