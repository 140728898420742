import { navigate } from "gatsby"
import { removeUser } from "../../Redux/UserSlice"
import { logout } from "./LoginSlice"
import { expired_ } from "./expired"

export const handleLogout = (dispatch) => {
  dispatch(expired_(true))
  
  // localStorage.clear("personalData")
  //   dispatch(logout())
  //   dispatch(removeUser())
  //   navigate("/login")
  }