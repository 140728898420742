import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
const initialState = {
  country: "Saudi Arabia",
}

export const getcountry = createAsyncThunk("countrySlice/getcountry", async () => {
  try {
       
    const headers = {
      "Content-Type": "application/json; charset=utf8",
    }
    const response = await axios.get("https://get.geojs.io/v1/ip/country.json", {
      headers,
    })

    return response.data
  } catch (error) {
    throw error
  }
})

const countrySlice = createSlice({
  name: "countrySlice",
  initialState,
  reducers: {
    setCountry: (state, action) => {
      state.country = action.payload
    },
  },
  extraReducers: builder => {
   // PENDING
    builder
        .addCase(getcountry.pending, (state, action) => {
          return {
            ...state,
          
          };
        })
      // FULFILLED
      .addCase(getcountry.fulfilled, (state, action) => {
        
      state.country =
        action.payload.name !== "Egypt"
          ? "Saudi Arabia"
          : "United States"
    })
    // REJECTED
    .addCase(getcountry.rejected, (state, action) => {
      console.log("rejected", action.error.message);
      
      return {
        ...state,
      };
    });
  },
})

export const { setCountry } = countrySlice.actions
export default countrySlice.reducer
